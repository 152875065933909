// markup
import * as React from "react";
import "./index.scss";

const TermsPage = () => {
    return (
        <main className="main-page">
            <div className="document-body">
                <h1>TÉRMINOS Y CONDICIONES QUICK APP</h1>
                <h2>
                    1.- Identificación y aceptación y acceso al Aplicación móvil
                </h2>
                <p>
                    Los presentes Términos y Condiciones regulan el acceso y uso
                    de la aplicación móvil QUICK …….__ titularidad de REDPYV,
                    S.L. (en adelante, “REDPYV”) con N.I.F B67775585 y con
                    domicilio social en Paseo de España, 16 - Piso 4 B, Jaén,
                    23009. El Usuario puede contactar con REDPYV a través de un
                    correo electrónico remitido a la dirección:
                    _redpyv@appquick__ o al número de teléfono/mensajería:
                    ___________ El acceso a la aplicación móvil supone la
                    aceptación de estos Términos y Condiciones y la condición de
                    usuario (en adelante, “Usuario” o “Usuarios”), así como las
                    diferentes modificaciones y/o textos legales adicionales que
                    REDPYV pueda incluir en el futuro. El acceso y navegación
                    por la aplicación móvil requerirá el registro de los
                    Usuarios y será totalmente gratuito.
                </p>
                <h2>2.- Objeto y Servicios</h2>
                <p>
                    Los presentes Términos y Condiciones tienen como objeto
                    regular las condiciones aplicables a los Usuario registrados
                    en la plataforma móvil REDPYV. REDPYV (en adelante,
                    “Aplicación móvil” o “Plataforma”) consiste en una
                    aplicación para dispositivos móviles que ofrece a los
                    usuarios registrados la posibilidad de obtener ingresos por
                    la visualización de anuncios publicitarios incluidos en la
                    Plataforma. La financiación de la Plataforma es obtenida de
                    la publicidad ofertada a través de la misma y permite a la
                    Plataforma el reparto de de un % de los ingresos entre los
                    usuarios mediante un sistema aleatorio no manipulable
                </p>
                <h2>
                    3.- Necesidad de Registro y creación de cuenta de Usuario
                </h2>
                <p>
                    Para poder acceder a los Servicios, los Usuarios deberán
                    crear una cuenta a través de un formulario online que deberá
                    ser cumplimentado con los datos requeridos en el mismo.
                    Solamente se permite la creación de una cuenta por Usuario.
                    Para el visionado de anuncios, solo podrá utilizarse un
                    dispositivo por cada cuenta de usuario. Si se usa más de una
                    cuenta para ver anuncios en un mismo dispositivo, todas las
                    cuentas involucradas serán permanentemente suspendidas o
                    bloqueadas. Los Usuarios podrán registrarse en la Plataforma
                    de forma manual o bien a través de las siguientes
                    modalidades: A. Registro a través de Facebook El Usuario
                    podrá registrarse a través de su perfil de Facebook,
                    introduciendo su usuario y contraseña de Facebook y obtener
                    una cuenta en la Plataforma. 2 Mediante el registro a través
                    de Facebook, el Usuario autoriza a que REDPYV pueda conocer
                    su perfil público y dirección de correo electrónico. B.
                    Registro a través de Google El Usuario podrá registrarse a
                    través de sus credenciales de Google, autorizando a que
                    REDPYV pueda conocer su perfil público y dirección de correo
                    electrónico. C. Registro a través de Apple Id. El Usuario
                    podrá registrarse a través de sus credenciales de Apple Id,
                    autorizando a que REDPYV pueda conocer su perfil público y
                    dirección de correo electrónico. 3.1 Responsabilidad de la
                    cuenta Los Usuarios tendrán la responsabilidad plena de uso
                    de su cuenta de Usuario. En consecuencia, los Usuarios son
                    responsables de la adecuada custodia y confidencialidad del
                    nombre de Usuario y/o contraseñas que le permitan el acceso
                    a su cuenta, y se comprometen a no ceder su uso a terceros,
                    ya sea temporal o permanente, ni a permitir su acceso a
                    terceras personas. En virtud de lo anterior, los Usuarios
                    deberán notificar de forma inmediata a REDPYV a través del
                    correo electrónico redpyv@appquickmoneycualquier uso
                    indebido de su nombre de Usuario y/o de su contraseña,
                    debido a circunstancias tales como el robo, extravío o el
                    acceso no autorizado a los mismos, con el fin de que REDPYV
                    pueda proceder a su cancelación o bloqueo y/o inhabilitación
                    tan pronto como le conste la indebida utilización de su
                    Cuenta de Usuario. Mientras no se comuniquen tales hechos,
                    REDPYV quedará eximida de cualquier responsabilidad que
                    pudiera derivarse del uso indebido de los nombres de
                    Usuarios o contraseñas por terceros no autorizados. El
                    Usuario declara que que dispone de capacidad legal
                    suficiente para contratar los servicios ofrecidos en la
                    Plataforma, según los presentes Términos y Condiciones.
                </p>
                <h2>4.- Conducta de los Usuarios en la Plataforma</h2>
                <p>
                    Los Usuarios se comprometen a hacer un uso lícito,
                    diligente, honesto y correcto de cuanta información o
                    contenidos tengan acceso a través de la Plataforma, y todo
                    ello bajo los principios de la buena fe y respetando en todo
                    momento a la legalidad vigente y a los presentes Términos y
                    Condiciones. En particular, pero sin limitación, los
                    Usuarios no deberán: i. Registrarse o comunicar datos que no
                    sean verdaderos, exactos, completos y/o actualizados, ni
                    acceder a la Aplicación móvil utilizando el nombre, datos
                    identificativos o el password de otro Usuario o suplantar a
                    cualquier persona o identidad. ii. Usar el Aplicación móvil
                    con fines fraudulentos, o relacionados con delitos penales o
                    actividades ilícitas de ningún tipo. 3 iii. Solicitar pagos
                    fraudulentos. REDPYV se reserva el derecho a denegar
                    cualquier intento de acceso a la Aplicación móvil, cancelar
                    cuentas, eliminar o modificar contenidos, o cancelar la
                    prestación de Servicios en los casos en los que se produzca
                    una utilización incorrecta de la Aplicación móvil.
                </p>
                <h2>5.- Pagos a los Usuarios</h2>
                <p>
                    Los tickets conseguidos por la visualización de los anuncios
                    darán derecho al Usuario a optar a la obtención de ingresos
                    otorgados mediante un sistema aleatorio. El Usuario tendrá
                    un límite de obtención de tickets, con un límite de 20
                    tickets diarios, acumulables por un máximo de tres días Las
                    cantidades conseguidas por el Usuario serán mostradas en su
                    cuenta de Usuario. Todas las cantidades económicas de su
                    cuenta de Usuario se muestran en euros. Todos los pagos
                    serán realizados mediante Transferencia. Puede consultar las
                    condiciones de__________ a través del siguiente link. Por el
                    momento, ningún otro método de pago está disponible y serán
                    enviados por el procesador de pagos en cinco días hábiles a
                    partir de su solicitud El importe mínimo acumulado en la
                    cuenta del Usuario para poder solicitar el primer pago
                    deberá ser de 10 €. Para cada pago puede ser descontada una
                    tasa dependiendo del procesador de pagos utilizado. Esta
                    tasa, en caso de existir, le será mostrada como valor
                    deducido antes de confirmar la solicitud del cobro. Algunos
                    métodos de pago podrían requerir un importe mínimo
                    diferente, en tales casos se informará previamente de dichos
                    importes. La cantidad máxima que un usuario puede solicitar
                    por cada pago es de El acumulado en su cuenta. Los usuarios
                    son responsables de seleccionar el método de pago que deseen
                    y de la información del mismo que proporcionen. Todos los
                    pagos serán procesados de acuerdo a la información
                    proporcionada por el usuario. En el caso de que falle un
                    pago y/o se cancele, las tasas que se apliquen incurrirán en
                    el usuario. Tras solicitar un pago, el usuario tendrá que
                    esperar 0 días para poder solicitar otro.
                </p>
                <h2>6.- Propiedad Intelectual</h2>
                <p>
                    Todos los derechos sobre el contenido, diseño y código
                    fuente de la Plataforma y, en especial, con carácter
                    enunciativo, pero no limitativo, todos los derechos sobre
                    las fotografías, imágenes, textos, logotipos, diseños,
                    marcas, nombres comerciales, datos que se incluyen en la
                    Plataforma y cualesquiera otros derechos de propiedad 4
                    intelectual e industrial , incluido el modelos de negocio
                    que representa, son titularidad de REDPYV, o bien de
                    terceros que han autorizado expresamente a REDPYV para la
                    utilización de los mismos en su Plataforma. Por ello y en
                    virtud de lo dispuesto en Real Decreto Legislativo 1/1996,
                    de 12 de abril, por el que se aprueba el texto refundido de
                    la Ley de Propiedad Intelectual, regularizando, aclarando y
                    armonizando las disposiciones legales vigentes sobre la
                    materia, así como en la Ley 17/2001, de 7 de diciembre de
                    Marcas y la legislación complementaria en materia de
                    propiedad intelectual e industrial, queda expresamente
                    prohibida la reproducción, transmisión, adaptación,
                    traducción, distribución, comunicación pública, incluida su
                    modalidad de puesta a disposición, de la totalidad o parte
                    de los contenidos del Aplicación móvil, en cualquier soporte
                    y por cualquier medio técnico, salvo autorización expresa
                    otorgada por escrito por parte de REDPYV. Cualquier uso de
                    esos contenidos no autorizado previamente por parte de
                    REDPYV será considerado un incumplimiento grave de los
                    derechos de propiedad intelectual o industrial y dará lugar
                    a las responsabilidades legalmente establecidas.
                </p>
                <h2>7.- Exclusión de responsabilidad</h2>
                <p>
                    El servicio prestado por REDPYV se limita a al mantenimiento
                    y puesta a disposición de la Plataforma a los Usuarios.
                    REDPYV no otorga ninguna garantía ni se hace responsable, en
                    ningún caso, de los daños y perjuicios de cualquier
                    naturaleza que pudieran derivarse del acceso o uso de los
                    contenidos de la Aplicación móvil. Entre otras, y a título
                    enunciativo y no limitativo, REDPYV no se responsabiliza de
                    las siguientes circunstancias: i. Del contenido de los
                    anuncios publicitarios mostrados a través de la Aplicación
                    Móvil. ii. De la falta de disponibilidad, mantenimiento y
                    efectivo funcionamiento de la Aplicación móvil y/o de sus
                    servicios o contenidos, excluyéndose, en la máxima medida
                    permitida por la legislación vigente, cualquier
                    responsabilidad por los daños y perjuicios de toda
                    naturaleza que puedan deberse a la falta de disponibilidad o
                    de continuidad del funcionamiento de la Aplicación móvil.
                    iii. De la falta de utilidad de la Aplicación móvil o los
                    contenidos para cualquier servicio. iv. Del contenido de
                    otras páginas de Internet a las que puedan dirigirse links o
                    enlaces colocados en la Aplicación móvil. v. De la
                    existencia de virus, programas maliciosos o lesivos en la
                    Aplicación móvil. vi. Del uso ilícito, negligente,
                    fraudulento, contrario a los presentes Términos y
                    Condiciones o a la buena fe, de la Aplicación móvil o sus
                    contenidos, por parte de los Usuarios, incluido cualquier
                    infracción de los derechos de Propiedad Intelectual y/o
                    Industrial de REDPYV o de terceros. vii. De las incidencias
                    ocasionadas por la falta de la diligencia debida por parte
                    del Usuario o por el uso indebido de la Aplicación móvil.
                    viii. De la publicación a través del Aplicación móvil de los
                    comentarios y opiniones que infrinjan las normas mencionadas
                    en los presentes Términos y Condiciones, la legislación
                    aplicable o los derechos de terceras partes. 5 ix. De los
                    casos de fuerza mayor, entendidos tales como el fallo,
                    suspensión o interrupción de los servicios o utilización de
                    la Aplicación móvil como consecuencia de las restricciones
                    de energía, el bloqueo de las telecomunicaciones o de la red
                    de Internet, acciones u omisiones de terceras personas,
                    operadores de telecomunicación o compañías de servicios,
                    suministro o transportes o cualesquiera otras causas o
                    circunstancias independientes de la voluntad de REDPYV que
                    impidan la utilización normal de la Aplicación móvil.
                </p>
                <h2>8.- Hipervínculos</h2>
                <p>
                    El Aplicación móvil puede contener hipervínculos que
                    permitan al Usuario acceder a páginas Web de terceros.
                    REDPYV no asume ninguna responsabilidad por el contenido,
                    informaciones o servicios que pudieran aparecer en dichos
                    sitios, que se entenderán ofrecidos exclusivamente con
                    carácter informativo por parte de REDPYV, y que en ningún
                    caso implican relación, aceptación o respaldo alguno entre
                    REDPYV y las personas o entidades titulares de tales
                    contenidos o titulares de los sitios donde se encuentren.
                </p>
                <h2>9.- Duración y modificaciones</h2>
                <p>
                    Se entienden como vigentes, las condiciones que estén
                    publicadas en el momento en el que el Usuario acceda a la
                    Plataforma de REDPYV. REDPYV se reserva el derecho a
                    realizar tantas modificaciones como considere oportunas en
                    los presentes Términos y Condiciones, en cuyo caso se les
                    comunicará a los Usuarios. Estas modificaciones serán
                    válidas desde su publicación en la Aplicación móvil.
                </p>
                <h2>10.- Cláusula de salvaguarda</h2>
                <p>
                    Todas las cláusulas o extremos de estas condiciones de uso
                    deben ser interpretadas de forma independiente y autónoma,
                    no viéndose afectadas el resto de las estipulaciones en caso
                    de que una de ellas haya sido declarada nula por sentencia
                    judicial o resolución arbitral firme. Se sustituirá la
                    cláusula o cláusulas afectadas por otra u otras que
                    preserven los efectos perseguidos por los Términos y
                    Condiciones. Si el Usuario tuviera alguna reclamación
                    respecto a la compra efectuada o al servicio ofrecido por la
                    Plataforma, puede ponerse en contacto en todo momento con
                    REDPYV mediante los siguientes datos de contacto:
                    _redpyv@appquickmoney__
                </p>
                <h2>11.- Idioma</h2>
                <p>
                    REDPYV puede traducir los presentes Términos y Condiciones o
                    cualquier otra política que pueda ser publicada en la
                    Plataforma. La versión en español será la que prevalezca en
                    caso de conflicto con otras traducciones.
                </p>
                <h2>12.-Resolución de controversias</h2>
                <p>
                    6 Los presentes Términos y Condiciones están sometidos a la
                    legislación española. Las partes, de conformidad al Real
                    Decreto Legislativo 1/2007, de 16 de noviembre, por el que
                    se aprueba el texto refundido de la Ley General para la
                    Defensa de los Consumidores y Usuarios y otras leyes
                    complementarias, se someten a los Juzgados y Tribunales del
                    lugar de residencia del Usuario.
                </p>
                <h2>13.-Avisos</h2>
                <p>
                    La aplicación podrá enviar a los usuarios registrados en la
                    plataforma avisos de TicKets disponibles, cuantías
                    disponibles, ingresos acumulados etc.
                </p>
            </div>
        </main>
    );
};

export default TermsPage;
